// custom scss
.bg-rm {
  background: transparent !important;
}
.d-none-after {
  &::after {
    display: none !important;
  }
}
.svg-custom {
  svg {
    padding: 1.35px;
  }
}
.menu {
  .menu-item > &,
  .menu-accordion > &,
  .show > .menu-link {
    font-weight: bold;
  }
}
.rounded-5 {
  border-radius: 2rem;
}
.table {
  tbody {
    tr:last-child {
      border-bottom: 0 !important;
    }
    td {
      vertical-align: middle !important;
    }
  }
  .text-black {
    color: #3f4254 !important;
  }
}
.lh-30px {
  line-height: 30px !important;
}
.d-row-hover {
  .d-cell-hover {
    display: none;
  }
  &:hover {
    .d-cell-hover {
      display: block;
    }
  }
}
// layout
.view-password {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  z-index: 1;
}
.select-custom {
  padding: 0 !important;
  &.select-control-solid {
    .select__control {
      background: #f9f9f9;
    }
  }
  .select__control {
    background: transparent;
    height: auto;
    min-height: calc(1.5em + 1.1rem + 2px);
    font-size: 0.95rem;
    border-radius: 0.425rem;
    border: 1px solid #fff;
    opacity: 1;
  }
  .select__single-value--is-disabled {
    opacity: 1;
    color: #000;
  }
  .css-1p25tiz-indicatorContainer,
  .css-c4ei7m-indicatorContainer {
    padding: 0 5px;
  }
}
.form-error {
  border: 1px solid #ff5454 !important;
  border-radius: 0.425rem;
}
.popup-in-popup {
  background: rgb(0 0 0 / 20%);
}
.bg-auth {
  background-color: #202455;
  background-size: cover;
  background-position: bottom;
  height: 100%;
}
.svg-custom {
  svg {
    padding: 1.35px;
  }
}
.custom-select-table {
  .select__control--is-disabled {
    background: #fff !important;
    opacity: 1 !important;
  }

  .select__single-value--is-disabled {
    opacity: 1 !important;
  }

  .select__indicators {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    z-index: 1;
  }

  .select__value-container {
    padding: 0;
    text-align: left;
  }

  .select__dropdown-indicator {
    display: none;
  }

  .select__clear-indicator {
    padding: 0;
    opacity: 0;
    display: none;
    background: #f2f5f8;
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
  }

  .select__control {
    height: 30px;
    min-height: auto;
    border: 0;
    border-radius: 0;
  }

  .select__single-value {
    font-size: 1rem;
  }

  .select__menu-list {
    .select__option {
      font-size: 0.8rem !important;
    }
  }
  .input-form {
    padding: 0 3px;
  }
}

.wrap-action-row {
  position: relative;

  .action-row-table {
    opacity: 0;
    position: absolute;
    width: 30px;
    height: 30px;
    // z-index: 1;
    right: 0;
  }

  &:hover {
    .action-row-table {
      opacity: 1;
      position: relative;
      z-index: 2;
    }

    .custom-select-table {
      .select__clear-indicator {
        opacity: 1;
        display: flex;
      }
    }
  }
}

.table-invoice {
  &.select-more {
    .wrap-checkinvoice {
      display: table-cell;
    }
  }

  .wrap-checkinvoice {
    display: none;
  }
}
.data-grid-container .data-grid .cell {
  > input.data-editor {
    outline: none !important;
    border: 0;
    height: 100%;
    text-align: left;
  }
  &.text-center > input.data-editor {
    text-align: center;
  }
}
td {
  > .value-viewer {
    // text-align: center;
    display: flex !important;
    justify-content: flex-start;
    align-items: center;
    padding: 2px 5px;
    height: 100%;
    text-align: left;
  }
  &.text-center {
    > .value-viewer {
      justify-content: center;
      text-align: center;
    }
  }
}
.data-grid-container .data-grid .cell.read-only {
  background: #fff !important;
  color: inherit !important;
}
.modal-custom-form {
  .modal-body {
    background: #eff2f5;
  }
}

.table-manual {
  td,
  th {
    border: 1px solid #c3c3c3 !important;
    padding: 3px;
    vertical-align: middle;
    &.form-error {
      .value-viewer{
        border: 1px solid #ff5454 !important;
      } 
    }
  }
  .hidden-add-row {
    tbody tr:last-child {
      display: none;
    }
  }
}
.h-el-after {
  &.dropdown-toggle::after {
    display: none;
  }
}
.data-grid-container .data-grid .cell {
  height: 30px !important;
}
.card-hover-info {
  .active {
    background: rgba(114, 57, 234, 0.8) !important;
    background-color: rgba(114, 57, 234, 0.8) !important;
    color: white;
  }
  &:hover {
    background: rgba(114, 57, 234, 0.8) !important;
    background-color: rgba(114, 57, 234, 0.8) !important;
    color: white;
  }
}
.events-none {
  pointer-events: none;
}
.text-ellipsis {
  text-overflow: ellipsis;
}
.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover {
  color: rgba(57, 57, 57, 0.4) !important;
}
.disabled {
  pointer-events: none;
}
.border-black {
  border-color: #000!important;
}
.table-print {
  thead {
    th {
      text-align: center;
      vertical-align: middle;
    }
  }

  td,
  th {
    border: 1px solid #000 ;
    padding: 2px;
    vertical-align: middle;
  }

  .border-0 {
    border: 0 !important;
  }
}

.print-pdf {
  .card {
    box-shadow: none;
    color: #000;
  }

  font-family: Arial,
  Helvetica,
  sans-serif;
  height: calc(var(--vh, 1vh) * 100);
  background: #fff;
  -webkit-print-color-adjust:exact !important;
  print-color-adjust:exact !important;

  .footer-pdf {
    background: #fff;
    border-bottom: 1px solid #fff;
    display: table-footer-group;
    height: 24px;
    // padding-right: 13px;
    page-break-after: always;
    position: absolute;
    right: 15px;
    left: 15px;
    top: 98.7vh;
    width: 97%;
    z-index: 2;
  }

  .footer-pdf.last {
    border-bottom: 0;
    height: 24px !important;
  }

  .footer-pdf.mid {
    border-bottom: 0;
    height: 35px;
  }

  .border {
    border-color: #000 !important;
  }
}
.hover-approval {
  position: relative;
  .in-approval {
    opacity: 0;
    visibility: hidden;
    transition: all .5s ease;
    position: absolute;
    top: 37px;
    right: 7%;
    z-index: 1;
    &::after {
      color: var(--bs-warning-light) !important;
      content: "\f5e3";
      vertical-align: -0.125em;
      fill: currentcolor;
      display: inline-block;
      font-family: bootstrap-icons!important;
      font-style: normal;
      font-weight: 400!important;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      vertical-align: -0.125em;
      -webkit-font-smoothing: antialiased;
      position: absolute;
      top: -9px;
      left: 50%;
      transform: translateX(-50%);
      box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
      z-index: 1;
      color: #fff;
    }
    &.custom-dasboard {
      position: inherit;
      .wrap-action-approval {
        display: flex;
        gap: 1.5rem;
         .menu-item {
          min-width: 92px;
          &.border {
            background: #50cd89;
          }
          background: #f1416c;
          border-radius: 6px;
          border: 0!important;
          .menu-link {
            color: #fff!important;
          }
         }
      }
    }
  }
  &:hover .in-approval {
    opacity: 1;
    visibility: visible;
  }
}
.custom-loading {
  overflow: hidden;
}

.loader {
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid #38c1f1;
  display: block;
  width: 80px;
  height: 80px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  margin: 20px auto 0;
}
.loader-mini {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #38c1f1;
  width: 20px;
  height: 20px;
  margin: 0;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
.h-100vh {
  height: calc(var(--vh, 1vh) * 100);
}
.font-normal {
  font-style: normal!important;
}
.menu-link {
  &:hover {
    .text-black {
      color: var(--bs-primary)!important;
    }
  }
  
}
.hover-bold {
  &:hover,&i:before {
    font-weight: 600!important;
    
  }

}
.wrap-logo-header {
  display: none;
}
.search-fix-header {
  position: fixed!important;
  top: 12px;
  left: 280px;
  z-index: 100;
  width: 30vw;
  display: flex;
  align-items: center;
  transition: all .4s ease;
}
[data-kt-app-sidebar-minimize=on] {
  .wrap-logo-header {
    display: block;
  }
  .search-fix-header {
    left: 230px;
  }
}
.btn-custom {
  &.btn-icon-custom {
    width: 50px;
    min-width: 50px;
    max-width: 50px;
  }
  min-width: 85px;
  height: 25px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.wrap-search-table {
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  left: 5px;
}
.app-header {
  background: #f5f8fa;
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.1)!important;
}
.scrollx-auto {
  overflow-x: auto;
}
.wrap-order {
  border-radius: 10px;
  overflow: hidden;
  .header-order {
    position: relative;
    .close-order {
      position: absolute;
      right: 6px;
      top: 6px;
    }
    .btn-abs-name-order {
      position: absolute;
      left: 50%;
      bottom: -15px;
      transform: translateX(-50%);
    }  
  }
  
  .wrap-line-promotion {
    .line-item {
      * {
        padding: 0 8px;
      }
    }
  }
}
.toolbar-custom-order {
  margin-top: -60px!important;
}
.number-circle {
  display: inline-block;
  width: 18px;
  height: 18px;
  font-size: 11px;
  background-color: #000;
  color: #fff;
  border-radius: 50%;
  text-align: center;
  line-height: 20px;
}
.gallery-order {
  -webkit-column-count: 4;
  -webkit-column-gap:   15px;
  -moz-column-count:    4;
  -moz-column-gap:      15px;
  column-count:         4;
  column-gap:           15px;
  .gallery-item {
    width: 100% !important;
  }
}
.item-tab {
  text-align: center;
  border-radius: 20px;
  padding: 6px 15px;
  // min-width: 30px;

  &.active {
    background: #3f4254;
    color: #fff;
  }
}
.right-detail {
  margin-top: -30px;
  min-height: 60vh;
  position: relative;
  >.card {
    position: sticky;
    top: 15px;
  }
}
.wrap-item {
  .item {
    position: relative;
    // border: 2px solid #f1f1f1!important;
    cursor: pointer;
    border-radius: 8px;
    .arrow {
      position: absolute;
      right: -13px;
      top: 55%;
      transform: translateY(-50%);
      visibility: hidden;
      i {
        // color: #3f4254;
      }
    }
    &.active,&:hover {
      box-shadow: 0 0.5rem 1.5rem 0.5rem rgba(0, 0, 0, 0.075) !important;
    }
    &.active {
      .arrow {
        visibility: visible;
      }
    }
  }
}
.tab-custom-hub {
  border-radius: 30px;
}
.close-event-s {
  position: absolute;
  right: 0;
  top: 0;
  padding: 9px;
}
.bi-circle-icon {
  border-radius: 50%;
  border: 1px solid;
  font-size: 8px!important;
  width: 12px;
  height: 12px;
  display: inline-flex!important;
  justify-content: center;
  align-items: center;
  padding: 0;
}
.custom-dots {
  bottom: 0!important;
}
.slick-slider {
  margin: 0 -2.5px;
  .slick-slide {
    >div {
      padding: 0 2.5px;
    }
  }
}
.wrap-itemdetails,.wrap-item-add {
  height: calc(var(--vh, 1vh) * 100 - 300px);
}
// .wrap-item-line {
//   height: calc(var(--vh, 1vh) * 100 - 290px) !important;
// }
// .wrap-item-line-member {
//   height: calc(var(--vh, 1vh) * 100 - 310px) !important;
// }
// .wrap-item-line-with-cmt {
//   height: calc(var(--vh, 1vh) * 100 - 315px) !important;
// }
// .wrap-item-line-with-cmt-member {
//   height: calc(var(--vh, 1vh) * 100 - 335px) !important;
// }
.slick-slider {
  .wrap-item-line {
    height: calc(var(--vh, 1vh) * 100 - 290px) !important;
  }
  .wrap-item-line-member {
    height: calc(var(--vh, 1vh) * 100 - 310px) !important;
  }
  .wrap-item-line-with-cmt {
    height: calc(var(--vh, 1vh) * 100 - 315px) !important;
  }
  .wrap-item-line-with-cmt-member {
    height: calc(var(--vh, 1vh) * 100 - 275px) !important;
  }
  .slick-dots li button:before {
    font-size: 20px!important;
  }
}
// .wrap-item-line-modal {
//   height: calc(var(--vh, 1vh) * 100 - 285px) !important;
// }
// .wrap-item-line-modal-with-cmt {
//   height: calc(var(--vh, 1vh) * 100 - 320px) !important;
// }
// .wrap-item-line-modal-member {
//   height: calc(var(--vh, 1vh) * 100 - 305px) !important;
// }
// .wrap-item-line-modal-with-cmt-member {
//   height: calc(var(--vh, 1vh) * 100 - 330px) !important;
// }
// .wrap-item-line-payment {
//   height: calc(var(--vh, 1vh) * 100 - 321px) !important;
// }
.wrap-slider-order {
  height: calc(var(--vh, 1vh) * 100 - 62px);
  .item-order-custom {
    height: calc(100% - 35px);
  }
  .wrap-item-y {
    height: calc(100% - 5px);
    overflow-y: auto;
  }
  .aside-right-order {
    overflow-x: auto;
  }
}
.warp-table-inf {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.h-120px {
  height: 120px;
}
.w-120px {
  width: 120px;
}
.backdrop-filter-blur-50 {
  backdrop-filter: blur(50px)
}
.wrap-left-order-service {
  max-height: calc(var(--vh, 1vh) * 100 - 60px);
  overflow-y: auto;
}
.volume-custom {
  top: 8px;
  right: 20px;
}
.top-2 {
  top: 2px;
}
.h-33{
  height: 33.33%;
}
.h-0 {
  height: 0;
}
.position-sticky-custom {
  position: sticky;
  top: 12px;
  z-index: 1;
}
.number-circle-c {
  top: 0;
  &.circle-custom {
    top: -15px;
    right: 5px;
  }
}

.circle-custom {
  top: -6px;
  right: 4px;
  animation-name: example;
  animation-duration: 100ms;
}
@keyframes example {
  from {top: 0;}
  to {top: -6px;}
}
.right-3px {
  right: 3px;
}
.slider-container.slick-dots li button:before {
  font-size: 14px;
}
.badge-custom-sale {
  height: 12px;
  line-height: 15px;
  display: inline-block;
  padding: 0px 3px;
  font-size: 9px;
}
.loading-calculate {
  position: relative;
}
.bg-blur {
  width: 100%;
  height: 100%;
  position: absolute;
  background: #ffffff1f;
  backdrop-filter: blur(5px);
  z-index: 1;
  top: 0;
  left: 0;
}
.loader-cal {
  width: 120px;
  height: 22px;
  border-radius: 40px;
  color: #009ef7;
  border: 2px solid;
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%,-50%);
  -webkit-transform: translate(-50%,-50%);
  -moz-transform: translate(-50%,-50%);
  
}
.loader-cal::before {
  content: "";
  position: absolute;
  margin: 2px;
  width: 30%;
  top: 0;
  bottom: 0;
  left: 0;
  border-radius: inherit;
  background: currentColor;
  animation: l3 1s infinite linear;
}
@keyframes l3 {
    50% {left:100%;transform: translateX(calc(-100% - 4px))}
}
.wrap-item-menu {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  height: 75px;
  font-size: 14px;
}
.offcanvas-custom {
  z-index: 10000;
}
.wrap-table-transfer {
  // height: calc(var(--vh, 1vh)* 75);
  overflow-y: auto;
}
.wrap-item-service {
  min-height: calc(var(--vh, 1vh)* 100 - 115px);
}
.row-reverse {
  flex-direction: row-reverse!important;
}
.Toastify {
  z-index: 100000!important;
}
.wrap-logs-printer {
  height: calc(var(--vh, 1vh)* 100 - 145px);
  overflow-y: auto;
}
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  top: 20px;
}

.lds-ellipsis div {
  position: absolute;
  top: -10px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #7c7c7c;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(24px, 0);
  }
}
.dup-icon {
  right: 15px;
  top: 15px;
}
.bg-pink {
  background: #a50064;
}
.border-right-momo {
  position: relative;
  &::after {
    content: "";
    width: 2px;
    height: 70%;
    position: absolute;
    right: 14px;
    top: 8px;
    background: #fff;
  }
  &.pink::after {
    background: #a50064;
  }
}
#canvas {
  width: 100px;
}
.wrap-loading::after {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  background: #ffffff69;
  backdrop-filter: blur(4px);
}
.h-81vh {
  height: 81vh;
}
.h-88vh {
  height: 88vh;
}