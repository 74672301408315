//
// CKEditor
//

// Base
.ck-target {
  display: none;
}

.ck-toolbar {
  @include border-radius($border-radius !important);
}

.ck-content {
  min-height: 200px;
  border-color: $gray-400 !important;
  @include border-radius($border-radius !important);

  &.ck-focused {
    // border-color: var(--#{$prefix}primary) !important;
    border-color: $gray-400 !important;
    box-shadow: none !important;
  }
  .placeholder {
    cursor: inherit !important;
    opacity: 1 !important;
  }
}

// CKEditor Classic & Document
.ck-editor {
  .ck-toolbar {
    @include border-top-radius($border-radius !important);
    @include border-bottom-radius(0 !important);
  }
  .ck-content {
    @include border-bottom-radius($border-radius !important);
    @include border-top-radius(0 !important);
  }
}

// CKEditor Inline / Balloon / Balloon Block
.ck-body {
  .ck-balloon-panel {
    .ck-content {
      min-height: 200px;
      border-color: transparent !important;

      &.ck-focused {
        border-color: var(--#{$prefix}primary) !important;
      }
    }

    &.ck-toolbar-container,
    .ck-toolbar {
      @include border-radius($border-radius !important);
    }
  }
}

.ck-editor-sm {
  .ck-toolbar {
    border-bottom: 0;
    border-color: $gray-400 !important;
    background-color: $gray-100 !important;

    .ck-button {
      font-size: 11px !important;

      svg {
        width: 17.5px;
        height: 17.5px;
      }
      .ck-icon > &,
      .ck-dropdown__arrow {
        width: 7px !important;
        z-index: auto !important;
      }
    }
    .ck-dropdown__panel {
      .ck-list {
        .ck-list__item > .ck-button {
          height: 20px;
        }
      }
      .ck-list-properties {
        .ck-button {
          width: 30px;
          height: 30px;

          svg {
            width: 30px;
            height: 30px;
          }
        }
      }
    }
  }
  .ck-editor__editable_inline {
    border-top: 0 !important;
  }
}
