.app-sidebar-wrapper {
    .menu-icon {
        svg {
            fill: #fff;
        }
    }
    .menu-link.active,.menu-item.show {
        svg {
            fill: #000
        }
    }
}