.flatpickr-wrapper {
  display: block !important;
  width: 100% !important;
}

.flatpickr-calendar {
  width: 260px !important;

  .flatpickr-months {
    display: flex;
    align-items: center;

    .flatpickr-prev-month,
    .flatpickr-next-month {
      padding: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0.475rem;
      top: .55rem !important;
      text-decoration: none;
      cursor: pointer;
      position: absolute;
      height: 34px;
      z-index: 3;
      color: rgba(0, 0, 0, 0.9);
      fill: rgba(0, 0, 0, 0.9);

      svg {
        fill: #a1a5b7;
        height: 13px;
        width: 13px;
      }

      &:hover {
        background: #f5f8fa;
        color: #959ea9;

        svg {
          fill: #5e6278;
        }
      }
    }

    .flatpickr-month {
      background: 0 0;
      color: #7e8299;
      fill: #7e8299;
      height: 46px;
      line-height: 1;
      text-align: center;
      position: relative;
      user-select: none;
      overflow: hidden;
      -webkit-box-flex: 1;
      flex: 1;

      .flatpickr-current-month {
        font-weight: 500;
        color: inherit;
        font-size: 10px;
        position: absolute;
        width: 75%;
        left: 12.5%;
        padding: 10px 0 0 0;
        line-height: 1;
        height: 34px;
        display: inline-block;
        text-align: center;
        transform: translate3d(0, 0, 0);

        .flatpickr-monthDropdown-months {
          border: 1px solid #d1d1d1 !important;
          background-color: #fff;
          color: #5e6278;
          padding: 0.5rem 0.75rem;
          margin-right: 0.5rem;
          outline: 0 !important;
          border-radius: 0.475rem;
          background: 0 0;
          font-family: inherit;
          height: auto;
          line-height: inherit;
          position: relative;
          vertical-align: initial;
          width: auto;

          &:hover {
            background: #f5f8fa;
          }

          .flatpickr-monthDropdown-month {
            color: #5e6278;
            background-color: transparent;
            outline: 0;
            padding: 0;
            border: 0 !important;
            outline: 0 !important;
            border-radius: 0.475rem;
            -webkit-appearance: none;
          }
        }

        .numInputWrapper {
          border: 1px solid #d1d1d1 !important;
          display: inline-block;
          border-radius: 0.475rem;
          position: relative;
          width: 65px;
          height: auto;

          &:hover {
            background: #f5f8fa;
          }

          input {
            width: 100%;
          }

          .cur-year {
            cursor: text;
            color: #5e6278;
            padding: 0.5rem 0.75rem;
            outline: 0 !important;
            background: 0 0;
            box-sizing: border-box;
            margin: 0;
            display: inline-block;
            font-family: inherit;
            line-height: inherit;
            height: auto;
            border: 0;
            border-radius: 0;
            vertical-align: initial;
            appearance: textfield;
          }

          span {
            border-left: 0 !important;
            border-top: 0 !important;
            border-bottom: 0 !important;
            border-right: 0 !important;

            &::after {
              display: block;
              content: "";
              position: absolute;
              top: 50% !important;
              transform: translateY(-50%);
            }
          }

          span.arrowUp {
            border-top-right-radius: 0.475rem;
            top: 0;

            &::after {
              border-bottom-color: #a1a5b7 !important;
              border-left: 4px solid transparent;
              border-right: 4px solid transparent;
              border-bottom: 4px solid rgba(57, 57, 57, 0.6);
            }
          }

          span.arrowDown {
            border-bottom-right-radius: 0.475rem;
            top: 50%;

            &::after {
              border-top-color: #a1a5b7 !important;
              border-left: 4px solid transparent;
              border-right: 4px solid transparent;
              border-top: 4px solid rgba(57, 57, 57, 0.6);
            }
          }
        }
      }
    }

    .flatpickr-prev-month {
      left: 0.5rem !important;
    }

    .flatpickr-next-month {
      right: 0.5rem !important;
    }
  }

  .flatpickr-innerContainer {
    // padding: 0 0.5rem 0.5rem 0.5rem !important;

    .flatpickr-rContainer {
      .flatpickr-weekdays {
        .flatpickr-weekdaycontainer {
          .flatpickr-weekday {
            font-size: 10px;
            background: transparent;
            text-align: center;
            overflow: hidden;
            width: 100%;
          }
        }
      }

      .flatpickr-days {
        width: 100%;

        .dayContainer {
          width: 100%;
          min-width: 100%;

          .flatpickr-day {
            font-size: 10px;
            border-radius: 0.475rem;
            box-shadow: none !important;
            height: 36px;
            line-height: 36px;
            color: #7e8299;
            margin-top: 0 !important;

            &:hover {
              cursor: pointer;
              outline: 0;
              background: #e6e6e6;
              border-color: #e6e6e6;
            }
          }

          .flatpickr-day.selected {
            color: #fff;
            background-color: #569ff7;
          }

          .flatpickr-day.today {
            border-color: #d1d1d1;
          }

          .prevMonthDay,
          .nextMonthDay {
            color: #b5b5c3;
            background: 0 0;
            border-color: transparent;
          }
        }
      }
    }
  }
}

.flatpickr-calendar.arrowTop::before,
.flatpickr-calendar.arrowTop::after {
  display: none;
}

.flatpickr-time.time24hr {
  .numInputWrapper {
    .numInput.flatpickr-hour,
    .numInput.flatpickr-minute {
      color: #5e6278;
      font-size: 1rem;
      font-weight: 500;
      &:hover {
        background: #f5f8fa;
      }
    }
    span {
      border-left: 0 !important;
      border-top: 0 !important;
      border-bottom: 0 !important;
      border-right: 0 !important;
      &:hover {
        opacity: 1;
      }
    }

    .arrowUp {
      &::after {
        border-bottom-color: #a1a5b7 !important;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-bottom: 4px solid rgba(57, 57, 57, 0.6);
      }
    }

    .arrowDown {
      &::after {
        border-top-color: #a1a5b7 !important;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 4px solid rgba(57, 57, 57, 0.6);
      }
    }
  }
}
