.input-login {
    width: 100%;
    padding: 10px;
    border: none;
    border-bottom: 2px solid #ccc;
    box-sizing: border-box;
    font-size: 16px;
    background: transparent;
    border-radius: 0;
}

.input-login:focus {
    outline: none;
    border-color: #202455;
}

.btn-login {
    width: 100%;
    padding: 8px 14px;
    background-color: #202455;
    border-radius: 5px;
    color: #fff;
    border: none;
    cursor: pointer;
    font-size: 16px;
}

.btn-login:hover {
    opacity: 0.8;
}

.btn-login-microsoft {
    width: 100%;
    padding: 8px 14px;
    border: 1.5px solid #dfdfdf;
    background: #fff;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.btn-login-microsoft:hover {
    background-color: #e9e9e9;
}

.btn-login-microsoft img {
    width: 25px;
    height: 25px;
    margin-right: 8px;
}

.logo-login img {
    width: auto;
    height: 90px;
}

@media screen and (max-width: 768px) {

    .logo-login img {
        height: 65px;
    }
}